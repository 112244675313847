import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import AutomationEdit       from "../Dialogs/AutomationEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Automation Dialogs
 * @returns {React.ReactElement}
 */
function AutomationDialogs() {
    const { automationID, automationTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("automation");
    const {
        fetchElem, activateAutomation, publishAutomation,
        clearAutomation, pauseAutomation, deleteAutomation,
    } = Store.useAction("automation");

    const { inActivate, action } = Store.useState("automationState");
    const { setAction, setInActivate, setInPublish } = Store.useAction("automationState");

    const { totalChanges, publishErrors } = Store.useState("automationEditor");
    const { fetchEditor } = Store.useAction("automationEditor");

    const { publishErrors : flowPublishErrors } = Store.useState("flowEditor");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Handles the Edit Submit
    const handleEdit = async (response) => {
        setAction();
        if (response.automationID !== elem.id) {
            navigate("SETTINGS", "AUTOMATIONS", response.automationID, "EDIT");
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        setAction();
        const result = await activateAutomation(elem.id);
        if (result.success) {
            setInActivate(false);
            setFlowInPublish(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Publish Submit
    const handlePublish = async () => {
        setAction();
        const result = await publishAutomation(elem.id);
        if (result.success) {
            setInPublish(false);
            setFlowInPublish(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Clear Submit
    const handleClear = async () => {
        setAction();
        const result = await clearAutomation(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        setAction();
        const result = await pauseAutomation(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        const result = await deleteAutomation(elem.id);
        if (result.success) {
            navigate("SETTINGS", "AUTOMATIONS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        if (publishErrors.length) {
            if (!Navigate.isUrl("EDIT", automationTab)) {
                navigate("SETTINGS", "AUTOMATIONS", automationID, "EDIT");
            } else {
                openDetails();
            }
        } else if (flowPublishErrors.length) {
            if (!Navigate.isUrl("FLOW_ONE", automationTab)) {
                navigate("SETTINGS", "AUTOMATIONS", automationID, "FLOW_ONE");
            } else {
                openDetails();
            }
        }
    };

    // Returns the Error Message
    const getErrorsText = () => {
        const parts = [
            publishErrors.length ? "AUTOMATION" : "FLOW",
            inActivate ? "ACTIVATE" : "PUBLISH",
            "PANEL",
        ];
        return parts.map((text) => NLS.get(`AUTOMATIONS_ERRORS_${text}`)).join(" ");
    };



    // Do the Render
    return <>
        <AutomationEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="AUTOMATIONS_ACTIVATE_TITLE"
            message="AUTOMATIONS_ACTIVATE_TEXT"
            content={elem.name}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <AlertDialog
            open={action.isPublish && totalChanges === 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="AUTOMATIONS_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish && totalChanges > 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={NLS.pluralize("AUTOMATIONS_PUBLISH_CHANGES", totalChanges, elem.name)}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="AUTOMATIONS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="AUTOMATIONS_PAUSE_TITLE"
            message="AUTOMATIONS_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="AUTOMATIONS_DELETE_TITLE"
            message="AUTOMATIONS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon={inActivate ? "check" : "publish"}
            title={inActivate ? "AUTOMATIONS_ACTIVATE_TITLE" : "GENERAL_PUBLISH_CHANGES"}
            message={getErrorsText()}
            onClose={handleErrors}
        />
    </>;
}

export default AutomationDialogs;
