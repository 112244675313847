import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Buttons = Styled.div`
    display: flex;
    gap: 12px;
    margin-top: 12px;
`;



/**
 * The Questionnaire Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function QuestionnaireEditor(props) {
    const {
        loading, isEdit, clientID, hasTabs, step, setStep,
        getValue, getError, getErrorCount,
        onChange, onQuestion, onSubmit, onDelete, onClose,
    } = props;

    const {
        maxQuestions, buttonLength, descLength, messageLength,
        variables, channelLinks, statuses,
    } = Store.useState("questionnaire");


    // Do the Render
    const questions = getValue("questions");

    return <PageContainer hasTabs={hasTabs} error={getError("form")}>
        <PageAccordion selected={step} onChange={setStep}>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="QUESTIONNAIRES_MAIN_TEXT"
                errorCount={getErrorCount("main")}
                withGap
            >
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={getValue("name")}
                    error={getError("name")}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={getValue("status")}
                    error={getError("status")}
                    onChange={onChange}
                    hideClear
                    isRequired
                />
            </AccordionItem>

            {questions.map((elem, index) => <AccordionItem
                key={index}
                value={String(index)}
                className={`questionnaire-${index}`}
                message={NLS.format("QUESTIONNAIRES_QUESTION_TITLE", String(index + 1))}
                description={elem.name}
                errorCount={getErrorCount("question", index)}
                withGap
            >
                <TextField
                    name="title"
                    label="GENERAL_TITLE"
                    maxLength={buttonLength}
                    value={elem.title || ""}
                    error={getError("title", index)}
                    onChange={(name, value) => onChange(name, value, index)}
                    isRequired
                />
                <TextField
                    name="question"
                    label="QUESTIONNAIRES_QUESTION"
                    maxLength={descLength}
                    value={elem.question || ""}
                    error={getError("question", index)}
                    variables={variables}
                    onChange={(name, value) => onChange(name, value, index)}
                />
                <TextareaField
                    name="answer"
                    label="QUESTIONNAIRES_ANSWER"
                    value={elem.answer || ""}
                    error={getError("answer", index)}
                    variables={variables}
                    channelLinks={channelLinks}
                    onChange={(name, value) => onChange(name, value, index)}
                    maxLength={messageLength}
                    isRequired
                />
                <MediaField
                    name="file"
                    value={elem.file || ""}
                    error={getError("file", index)}
                    clientID={clientID}
                    onChange={(name, value) => onChange(name, value, index)}
                />
                <Buttons>
                    <Button
                        variant="outlined"
                        icon="delete"
                        message="GENERAL_REMOVE"
                        onClick={() => onQuestion("remove", index)}
                    />
                    <Button
                        isHidden={index === 0}
                        variant="outlined"
                        icon="up"
                        message="GENERAL_MOVE"
                        onClick={() => onQuestion("up", index)}
                    />
                    <Button
                        isHidden={index === questions.length - 1}
                        variant="outlined"
                        icon="down"
                        message="GENERAL_MOVE"
                        onClick={() => onQuestion("down", index)}
                    />
                </Buttons>
            </AccordionItem>)}
        </PageAccordion>

        <PageFooter>
            <Button
                isHidden={questions.length === maxQuestions - 1}
                variant="primary"
                message="QUESTIONNAIRES_ADD_QUESTION"
                onClick={() => onQuestion("add")}
                isDisabled={loading}
            />
            <Button
                variant="primary"
                message="GENERAL_SAVE"
                onClick={onSubmit}
                isDisabled={loading}
            />
            <Button
                isHidden={!isEdit}
                variant="primary"
                message="GENERAL_DELETE"
                onClick={onDelete}
            />
            <Button
                variant="cancel"
                message="GENERAL_CANCEL"
                onClick={onClose}
            />
        </PageFooter>
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
QuestionnaireEditor.propTypes = {
    loading       : PropTypes.bool.isRequired,
    isEdit        : PropTypes.bool.isRequired,
    clientID      : PropTypes.number.isRequired,
    hasTabs       : PropTypes.bool.isRequired,
    step          : PropTypes.string.isRequired,
    setStep       : PropTypes.func.isRequired,
    getValue      : PropTypes.func.isRequired,
    getError      : PropTypes.func.isRequired,
    getErrorCount : PropTypes.func.isRequired,
    onChange      : PropTypes.func.isRequired,
    onQuestion    : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
};

export default QuestionnaireEditor;
