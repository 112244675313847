import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ChannelMenu          from "Components/Utils/Menus/ChannelMenu";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";
import IconLink             from "Dashboard/Components/Link/IconLink";



/**
 * The WABA Template Body
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateBody(props) {
    const { isDisabled, getValue, getError, setData } = props;

    const { bodyLength, variables, channelLinks } = Store.useState("wabaTemplate");

    // The References
    const inputRef   = React.useRef(null);
    const emojiRef   = React.useRef(null);
    const channelRef = React.useRef(null);

    // The Current State
    const [ menu, setMenu ] = React.useState("");

    // Some Variables
    const bodyText     = getValue("bodyText");
    const sampleList   = getValue("sampleList",   []);
    const variableList = getValue("variableList", []);


    // Handles the Format
    const handleFormat = (character) => {
        const newBodyText = Utils.formatText(inputRef, bodyText, character);
        setData("Body", { bodyText : newBodyText });
        inputRef.current.focus();
    };

    // Handles the Emoji Click
    const handleEmojiClick = (emoji) => {
        const newBodyText = Utils.insertText(inputRef, bodyText, emoji);
        setData("Body", { bodyText : newBodyText });
        setMenu("");
        inputRef.current.focus();
    };

    // Handles the Channel Click
    const handleChannelClick = (bodyText) => {
        setData("Body", { bodyText });
        setMenu("");
        inputRef.current.focus();
    };


    // Handles the Text
    const handleText = (name, value) => {
        const replaceables    = value.match(/{{[0-9]+}}/g);
        const numbers         = [];
        const numeration      = {};

        let   bodyText        = value;
        let   variableCount   = 0;
        let   newSampleList   = [ ...sampleList ];
        let   newVariableList = [ ...getValue("variableList") ];

        if (replaceables) {
            for (const replaceable of replaceables) {
                numbers.push(Number(replaceable.replace("{{", "").replace("}}", "")));
            }
            numbers.sort((a, b) => a - b);
            for (const [ index, number ] of numbers.entries()) {
                numeration[number] = index + 1;
            }
            for (const [ oldNumber, newNumber ] of Object.entries(numeration)) {
                bodyText      = bodyText.replace(`{{${oldNumber}}}`, `{{${newNumber}}}`);
                variableCount = Math.max(variableCount, newNumber);

                const oldIndex        = Number(oldNumber) - 1;
                const newIndex        = Number(newNumber) - 1;
                const sampleTemp      = newSampleList[newIndex];
                const variableTemp    = newVariableList[newIndex];

                newSampleList[newIndex] = newSampleList[oldIndex] || "";
                newSampleList[oldIndex] = sampleTemp || "";

                newVariableList[newIndex] = newVariableList[oldIndex] || "";
                newVariableList[oldIndex] = variableTemp || "";
            }
        }

        if (newSampleList.length > variableCount) {
            newSampleList   = newSampleList.slice(0, variableCount);
            newVariableList = newVariableList.slice(0, variableCount);
        } else {
            while (variableCount > newSampleList.length) {
                newSampleList.push("");
                newVariableList.push("");
            }
        }
        setData("Body", {
            bodyText, variableCount,
            sampleList   : newSampleList,
            variableList : newVariableList,
        });
    };

    // Handles the Variable
    const handleVariable = () => {
        const variableCount   = sampleList.length + 1;
        const newBodyText     = Utils.insertText(inputRef, bodyText, `{{${variableCount}}}`);
        const newSampleList   = [ ...sampleList,   "" ];
        const newVariableList = [ ...variableList, ""  ];
        setData("Body", {
            variableCount,
            bodyText     : newBodyText,
            sampleList   : newSampleList,
            variableList : newVariableList,
        });
        inputRef.current.focus();
    };

    // Handles the Sample
    const handleSample = (index, value) => {
        const newSampleList = [ ...sampleList ];
        newSampleList[Number(index)] = value;
        setData("Body", { sampleList : newSampleList });
    };

    // Handles the VariableID
    const handleVariableValue = (index, value) => {
        const newVariableList = [ ...variableList ];
        newVariableList[Number(index)] = value;
        setData("Body", { variableList : newVariableList });
    };



    // Do the Render
    const showValue = Boolean(!getValue("forAutomation"));

    return <>
        <InputField
            passedRef={inputRef}
            type="textarea"
            name="bodyText"
            label="WABA_TEMPLATES_BODY_TITLE"
            maxLength={bodyLength}
            value={bodyText}
            error={getError("bodyText")}
            onChange={handleText}
            isDisabled={isDisabled}
            isRequired
        >
            <IconLink
                passedRef={emojiRef}
                variant="black"
                icon="emoji"
                tooltip="EMOJI_NAME"
                onClick={() => setMenu("emoji")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="bold"
                tooltip="GENERAL_FORMAT_BOLD"
                onClick={() => handleFormat("*")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="italic"
                tooltip="GENERAL_FORMAT_ITALIC"
                onClick={() => handleFormat("_")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="strikethrough"
                tooltip="GENERAL_FORMAT_STRIKETHROUGH"
                onClick={() => handleFormat("~")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="code"
                tooltip="GENERAL_FORMAT_CODE"
                onClick={() => handleFormat("```")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="variable"
                tooltip="GENERAL_VARIABLES"
                onClick={handleVariable}
                isSmall
            />
            <IconLink
                isHidden={!channelLinks.length}
                passedRef={channelRef}
                variant="black"
                icon="channel"
                tooltip="CHANNELS_SINGULAR"
                onClick={() => setMenu("channel")}
                isSmall
            />
        </InputField>

        {sampleList.map((value, index) => <Columns key={index} topSpace={16}>
            <InputField
                className={!showValue ? "columns-double" : ""}
                name={`sampleList-${index}`}
                label={NLS.format("WABA_TEMPLATES_SAMPLE", String(index + 1))}
                value={value}
                error={getError(`sampleList-${index}`)}
                onChange={(name, value) => handleSample(index, value)}
                isDisabled={isDisabled}
                isRequired
            />
            <InputField
                isHidden={!showValue}
                type="select"
                name={`variableList-${index}`}
                label={NLS.format("WABA_TEMPLATES_VALUE", String(index + 1))}
                options={variables}
                value={variableList[index] || 0}
                error={getError(`variableList-${index}`)}
                onChange={(name, value) => handleVariableValue(index, value)}
                isRequired
            />
        </Columns>)}

        <EmojiPopup
            open={menu === "emoji"}
            targetRef={emojiRef}
            direction="bottom right"
            onClick={handleEmojiClick}
            onClose={() => setMenu("")}
        />
        <ChannelMenu
            open={menu === "channel"}
            targetRef={channelRef}
            inputRef={inputRef}
            message={bodyText}
            list={channelLinks}
            direction="bottom left"
            onClick={handleChannelClick}
            onClose={() => setMenu("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateBody.propTypes = {
    isDisabled : PropTypes.bool.isRequired,
    getValue   : PropTypes.func.isRequired,
    getError   : PropTypes.func.isRequired,
    setData    : PropTypes.func.isRequired,
};

export default WabaTemplateBody;
