import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";



/**
 * The Hospitality Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityHeader(props) {
    const { startAction, isExporting } = props;

    const {
        canEdit, canFilter, canImport, canExport,
        total, elem, contact, hasFilters,
    } = Store.useState("hospitality");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Variables
    const hasEdit    = canEdit && !!elem.id;
    const hasContact = !!contact.id;
    const hasExport  = canExport && total > 0;
    const hasActions = hasEdit || hasContact || hasExport || canImport;


    // Do the Render
    return <Header message="HOSPITALITY_NAME" icon="hospitality">
        <ActionList onAction={handleAction}>
            <ActionItem
                isHidden={!canFilter}
                action="FILTER"
                withMark={hasFilters}
            />
            <ActionItem
                isHidden={!hasActions}
                icon="more"
                message="GENERAL_ACTIONS"
                isLoading={isExporting}
            >
                <ActionOption
                    isHidden={!canEdit}
                    action="CREATE"
                    message="HOSPITALITY_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="EDIT"
                    message="HOSPITALITY_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!hasContact}
                    action="CONVERSATION"
                    message="CONVERSATIONS_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasContact}
                    action="CONTACT"
                    message="CONTACTS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="DELETE"
                    message="HOSPITALITY_DELETE_TITLE"
                />

                <MenuLine />
                <ActionOption
                    isHidden={!canImport}
                    action="IMPORT"
                    message="HOSPITALITY_IMPORT_TITLE"
                />
                <ActionOption
                    isHidden={!hasExport}
                    action="EXPORT"
                    message="HOSPITALITY_EXPORT_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityHeader.propTypes = {
    startAction : PropTypes.func.isRequired,
    isExporting : PropTypes.bool.isRequired,
};

export default HospitalityHeader;
