import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useDrag              from "Dashboard/Hooks/Drag";

// Components
import WidgetSection        from "./WidgetSection";
import WidgetPlatform       from "./WidgetPlatform";
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";

// Dashboard
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const NonePlatforms = Styled(NoneAvailable)`
    margin-top: -8px;
    margin-bottom: 24px;
`;

const PlatformList = Styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: -8px 0 0 0;
    padding: 0;
`;

const PlatformItem = Styled.li`
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px 8px 4px 16px;
    background-color: var(--light-gray);
    border-radius: var(--border-radius);
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--lighter-gray);
    }
`;

const PlatformText = Styled.div`
    flex-grow: 2;
`;

const PlatformButton = Styled(Button)`
    --button-background: transparent;
    --button-border: transparent;
    --button-color: var(--primary-color);
    margin-left: -8px;
`;



/**
 * The Widget Platforms
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetPlatforms(props) {
    const { onChange, onClear, forceClear, getText } = props;

    const { elem, platformsDraft, hasPlatforms } = Store.useState("widget");
    const { data, errors } = Store.useState("widgetState");
    const { sortPlatforms, deletePlatform } = Store.useAction("widgetPlatform");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        deletePlatform(elemID);
    };

    // Handles the Item Grab
    const handleGrab = (e, itemID, index) => {
        const node = e.target.parentElement;
        pick(e, node, node.parentElement, itemID, index);
    };

    // Handles the Drop
    const handleDrop = async () => {
        const list = platformsDraft.map(({ id }) => id);
        swap(list);
        swap(platformsDraft);
        sortPlatforms(elem.id, list);
    };

    // The Drag
    const { pick, swap } = useDrag(handleDrop);



    // Do the Render
    const showPlatforms = Utils.isActive(data.showPlatforms);
    const platform      = Utils.getValue(platformsDraft, "platformID", elemID);
    const hasQRCode     = platformsDraft.filter(({ showQR }) => showQR).length > 0;
    const elemName      = platform.name || platform.providerName;

    return <>
        <WidgetSection title="WIDGETS_GENERAL">
            <InputField
                className="columns-double"
                type="toggle"
                name="showPlatforms"
                label="WIDGETS_SHOW_PLATFORMS"
                value={Number(data.showPlatforms)}
                onChange={onChange}
                withBorder
            />
        </WidgetSection>

        <WidgetSection
            title="WIDGETS_PLATFORMS_NAME"
            button={<Button
                variant="primary"
                message="GENERAL_ADD"
                icon="add"
                onClick={() => startAction("ADD")}
            />}
            withColumns={false}
        >
            <NonePlatforms
                isHidden={hasPlatforms}
                message="WIDGETS_PLATFORMS_NONE_AVAILABLE"
            />

            {hasPlatforms && <PlatformList>
                {platformsDraft.map((item, index) => <PlatformItem key={item.id}>
                    <Icon
                        icon="drag"
                        cursor="grab"
                        onMouseDown={(e) => handleGrab(e, item.id, index)}
                        size="14"
                    />
                    <ChannelIcon
                        icon={item.icon}
                        color={item.color}
                        size={20}
                    />
                    <PlatformText onClick={() => startAction("EDIT", item.id)}>
                        {item.name}
                    </PlatformText>
                    <PlatformButton
                        variant="none"
                        icon="edit"
                        onClick={() => startAction("EDIT", item.id)}
                        isSmall
                    />
                    <PlatformButton
                        variant="none"
                        icon="delete"
                        onClick={() => startAction("DELETE", item.id)}
                        isSmall
                    />
                </PlatformItem>)}
            </PlatformList>}
        </WidgetSection>

        <WidgetSection
            isHidden={!showPlatforms || !hasPlatforms}
            title="WIDGETS_TEXTS"
            lastDouble
        >
            <InputField
                name="platformsText"
                label="WIDGETS_PLATFORMS_TEXT"
                value={getText("platformsText")}
                error={errors.platformsText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("platformsText")}
                shrinkLabel
            />
            <InputField
                isHidden={!hasQRCode}
                name="connectText"
                label="WIDGETS_CONNECT_TEXT"
                value={getText("connectText")}
                error={errors.connectText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("connectText")}
                shrinkLabel
            />
            <InputField
                isHidden={!hasQRCode}
                name="webLinkText"
                label="WIDGETS_WEB_LINK_TEXT"
                value={getText("webLinkText")}
                error={errors.webLinkText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("webLinkText")}
                shrinkLabel
            />
            <InputField
                isHidden={!hasQRCode}
                name="qrCodeText"
                label="WIDGETS_QR_CODE_TEXT"
                value={getText("qrCodeText")}
                error={errors.qrCodeText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("qrCodeText")}
                shrinkLabel
            />
        </WidgetSection>

        <WidgetPlatform
            open={action.isVCE}
            elemID={elemID}
            widgetID={elem.widgetID}
            onSubmit={endAction}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="WIDGETS_PLATFORMS_DELETE_TITLE"
            message="WIDGETS_PLATFORMS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetPlatforms.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetPlatforms;
