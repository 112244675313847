import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import AutomationHeader     from "./View/AutomationHeader";
import AutomationReport     from "./View/AutomationReport";
import AutomationExecs      from "./View/AutomationExecs";
import AutomationDialogs    from "./View/AutomationDialogs";
import AutomationEditor     from "./Editor/AutomationEditor";
import FlowEditor           from "Components/App/Config/Flow/Editor/FlowEditor";
import ConnectorLogContent  from "Components/App/Log/ConnectorLog/ConnectorLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Automation Page
 * @returns {React.ReactElement}
 */
function AutomationPage() {
    const { automationID, automationTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { charging, elem } = Store.useState("automation");

    const { fetchElem } = Store.useAction("automation");
    const { setInPublish } = Store.useAction("automationState");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Load the Data
    React.useEffect(() => {
        handleLoad(automationID);
        setInPublish(false);
        setFlowInPublish(false);
    }, [ automationID ]);

    // Handle the Load
    const handleLoad = async (automationID) => {
        const { elem } = await fetchElem(automationID);
        if (elem && !automationTab) {
            navigate("SETTINGS", "AUTOMATIONS", automationID, "EDIT");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "AUTOMATIONS", automationID, url);
    };


    // Variables
    const isEdit       = Navigate.isUrl("EDIT",           automationTab);
    const isFlow       = Navigate.isUrl("FLOW_ONE",       automationTab);
    const isReport     = Navigate.isUrl("REPORT",         automationTab);
    const isExecutions = Navigate.isUrl("EXECUTIONS",     automationTab);
    const isConnectors = Navigate.isUrl("LOG_CONNECTORS", automationTab);


    // Do the Render
    return <>
        <Main withNavigation>
            <AutomationHeader levels={2} />

            <Content isLoading={charging}>
                <TabList
                    isHidden={!elem.hasTabs}
                    variant="lined"
                    selected={automationTab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_EDITOR"
                        icon="edit"
                        url="EDIT"
                    />
                    <TabItem
                        isHidden={!elem.flowActiveDraft}
                        message="FLOWS_SINGULAR"
                        icon="flow"
                        url="FLOW_ONE"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="GENERAL_REPORT"
                        icon="report"
                        url="REPORT"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="AUTOMATIONS_EXECS_NAME"
                        icon="simulate"
                        url="EXECUTIONS"
                    />
                    <TabItem
                        isHidden={elem.isDraft || !elem.hasLog}
                        message="GENERAL_LOG"
                        icon="connector-log"
                        url="LOG_CONNECTORS"
                    />
                </TabList>

                {isEdit && <AutomationEditor
                    automationID={automationID}
                    hasTabs={elem.hasTabs || false}
                />}
                {isFlow && <FlowEditor
                    flowID={elem.flowID}
                    hasTabs
                />}

                {isReport && <AutomationReport />}
                {isExecutions && <AutomationExecs />}
                {isConnectors && <ConnectorLogContent type="AUTOMATION" />}
            </Content>
        </Main>

        <AutomationDialogs />
    </>;
}

export default AutomationPage;
