import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Components
import QuestionnaireHeader  from "./View/QuestionnaireHeader";
import QuestionnaireEditor  from "./View/QuestionnaireEditor";
import QuestionnairePreview from "./View/QuestionnairePreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Questionnaire Page
 * @returns {React.ReactElement}
 */
function QuestionnairePage() {
    const { questionnaireID } = Navigate.useParams();

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(questionnaireID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, tongues } = Store.useState("questionnaire");
    const { fetchElem, fetchEditData, editQuestionnaire, deleteQuestionnaire } = Store.useAction("questionnaire");


    // The Fields
    const fields = {
        main     : [ "name", "position", "status" ],
        question : [ "title", "question", "answer", "file" ],
    };

    // The Current State
    const [ tongueID,   setTongueID   ] = React.useState(0);
    const [ step,       setStep       ] = React.useState("");
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        questionnaireID : 0,
        clientID        : 0,
        name            : "",
        position        : 0,
        status          : "Active",
        questions       : {},
    };


    // Handles the Edit
    const handleEdit = async (data) => {
        await editQuestionnaire({
            ...data, clientID,
            questions : JSON.stringify(data.questions),
        });
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteQuestionnaire(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setElem, setData, setErrors, handleSubmit,
    } = useForm("questionnaire", initialData, handleEdit, handleClose, false);


    // Load the Data
    React.useEffect(() => {
        if (questionnaireID) {
            fetchElem(questionnaireID);
        } else {
            fetchEditData(clientID);
        }
        setStep("main");
    }, [ questionnaireID ]);

    // Store the Data
    React.useEffect(() => {
        if (elem.id) {
            const questionnaireID = isEdit ? elem.questionnaireID : 0;
            const questions       = {};
            let   maxQuestions    = 1;

            for (const question of elem.questions) {
                if (!questions[question.tongueID]) {
                    questions[question.tongueID] = [];
                }
                questions[question.tongueID].push(question);
                maxQuestions = Math.max(maxQuestions, questions[question.tongueID].length);
            }

            for (const { key } of tongues) {
                if (!questions[key]) {
                    questions[key] = [];
                    for (let i = 0; i < maxQuestions; i += 1) {
                        questions[key].push({ title : "" });
                    }
                }
            }

            setElem({ ...elem, questionnaireID, questions });
        } else {
            const questions = {};
            for (const { key } of tongues) {
                questions[key] = [{ title : "" }];
            }
            setElem({ ...initialData, questions });
        }
    }, [ edition ]);

    // Set the initial tongue
    React.useEffect(() => {
        if (!charging && tongues.length) {
            setTongueID(tongues[0].key);
        }
    }, [ charging ]);


    // Returns the Value
    const getValue = (name, defaultValue = "") => {
        if (name === "questions") {
            return data.questions[tongueID] || [];
        }
        return data[name] || defaultValue;
    };

    // Returns the Error
    const getError = (name, index) => {
        if (name === "form" && !errors.form) {
            return Commons.getLanguageError(tongues, errors, 2);
        }
        if (index === undefined) {
            return errors[name];
        }
        return errors[`${name}-${index}-${tongueID}`];
    };

    // Returns the Error Count in the Step
    const getErrorCount = (step, index) => {
        return Commons.getErrorCount(fields, errors, step, tongueID, index);
    };


    // Handles the Change
    const handleChange = (name, value, index) => {
        if (index === undefined) {
            setData({ ...data,   [name] : value });
            setErrors({ ...errors, [name] : "" });
            return;
        }

        const list = Utils.clone(data.questions[tongueID]);
        list[index][name] = value;

        const questions = { ...data.questions, [tongueID] : list };
        setData({ ...data, questions });
        setErrors({ ...errors, [`${name}-${index}-${tongueID}`] : "" });
    };

    // Handles the Question
    const handleQuestion = (action, index) => {
        const questions = Utils.clone(data.questions);
        let   newIndex  = null;

        for (const { key } of tongues) {
            const list = questions[key];
            switch (action) {
            case "add":
                list.push({ title : "" });
                newIndex = list.length - 1;
                break;
            case "remove":
                list.splice(index, 1);
                if (index - 1 > 0) {
                    newIndex = index - 1;
                }
                break;
            case "up":
                if (index > 0) {
                    [ list[index], list[index - 1] ]  = [ list[index - 1], list[index] ];
                    newIndex = index - 1;
                }
                break;
            case "down":
                if (index < list.length - 1) {
                    [ list[index], list[index + 1] ]  = [ list[index + 1], list[index] ];
                    newIndex = index + 1;
                }
                break;
            default:
            }
        }

        setData({ ...data, questions });
        if (newIndex !== null) {
            setStep(String(newIndex));
            window.setTimeout(() => Utils.scrollIntoView(`.questionnaire-${newIndex}`), 100);
        }
    };



    // Do the Render
    const showTongues = Boolean(tongues.length > 1);

    return <>
        <Main withNavigation withDetails>
            <QuestionnaireHeader
                data={data}
                href={goto}
                isEdit={isEdit}
            />

            <Content isLoading={charging}>
                {showTongues && <TabList
                    variant="lined"
                    selected={tongueID}
                    onClick={setTongueID}
                >
                    {tongues.map(({ key, value }) => <TabItem
                        key={key}
                        message={value}
                        value={key}
                    />)}
                </TabList>}

                <QuestionnaireEditor
                    loading={loading}
                    isEdit={isEdit}
                    clientID={clientID}
                    hasTabs={showTongues}
                    step={step}
                    setStep={setStep}
                    getValue={getValue}
                    getError={getError}
                    getErrorCount={getErrorCount}
                    onChange={handleChange}
                    onQuestion={handleQuestion}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </Content>
        </Main>

        <QuestionnairePreview
            clientID={clientID}
            getValue={getValue}
        />

        <DeleteDialog
            open={showDelete}
            title="QUESTIONNAIRES_DELETE_TITLE"
            message="QUESTIONNAIRES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

export default QuestionnairePage;
