import React                from "react";

// Components
import AnnouncementList     from "Components/App/Setup/Announcement/AnnouncementList";
import PlanList             from "Components/App/Setup/Plan/PlanList";
import AddonList            from "Components/App/Setup/Addon/AddonList";
import ConnectorTypeList    from "Components/App/Setup/ConnectorType/ConnectorTypeList";
import CurrencyList         from "Components/App/Setup/Currency/CurrencyList";
import AdminList            from "Components/App/Setup/Admin/AdminList";
import LanguageList         from "Components/App/Setup/Language/LanguageList";
import CountryList          from "Components/App/Setup/Country/CountryList";
import ProvinceList         from "Components/App/Setup/Province/ProvinceList";
import AssistantModelList   from "Components/App/Setup/AssistantModel/AssistantModelList";
import FlowTemplateList     from "Components/App/Setup/FlowTemplate/FlowTemplateList";
import FlowTemplatePage     from "Components/App/Setup/FlowTemplate/FlowTemplatePage";
import AutomationTempList   from "Components/App/Setup/AutomationTemp/AutomationTempList";
import AutomationTempPage   from "Components/App/Setup/AutomationTemp/AutomationTempPage";
import MediaPage            from "Components/App/Setup/Media/MediaPage";
import EmailTemplateList    from "Components/App/Email/EmailTemplate/EmailTemplateList";
import EmailWhiteList       from "Components/App/Email/EmailWhiteList/EmailWhiteList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Setup Container
 * @returns {React.ReactElement}
 */
function SetupContainer() {
    return <>
        <SecondaryNav message="GENERAL_SETTINGS" icon="settings">
            <NavigationList>
                <NavigationItem
                    message="ANNOUNCEMENTS_NAME"
                    url="ANNOUNCEMENTS"
                    icon="announcement"
                />
                <NavigationItem
                    message="PLANS_NAME"
                    url="PLANS"
                    icon="plan"
                />
                <NavigationItem
                    message="ADDONS_NAME"
                    url="ADDONS"
                    icon="addon"
                />
                <NavigationItem
                    message="CONNECTORS_NAME"
                    url="CONNECTORS"
                    icon="connector"
                />
                <NavigationItem
                    message="CURRENCIES_NAME"
                    url="CURRENCIES"
                    icon="currency"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="ADMINS_NAME"
                    url="ADMINS"
                    icon="admin"
                />
                <NavigationItem
                    message="LANGUAGES_NAME"
                    url="LANGUAGES"
                    icon="language"
                />
                <NavigationItem
                    message="COUNTRIES_NAME"
                    url="COUNTRIES"
                    icon="country"
                />
                <NavigationItem
                    message="PROVINCES_NAME"
                    url="PROVINCES"
                    icon="province"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="ASSISTANTS_MODELS_NAME"
                    url="ASSISTANTS_MODELS"
                    icon="assistant-model"
                />
                <NavigationItem
                    message="FLOWS_TEMPLATES_NAME"
                    url="FLOWS"
                    icon="flow"
                />
                <NavigationItem
                    message="AUTOMATIONS_TEMPS_NAME"
                    url="AUTOMATIONS"
                    icon="automation"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="MEDIA_NAME"
                    url="MEDIA"
                    icon="media"
                />
                <NavigationItem
                    message="EMAIL_TEMPLATES_NAME"
                    url="EMAIL_TEMPLATES"
                    icon="email"
                />
                <NavigationItem
                    message="EMAIL_WHITE_LIST_NAME"
                    url="EMAIL_WHITE_LIST"
                    icon="white-email"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="ANNOUNCEMENTS"     component={AnnouncementList}    />
            <AdminRoute url="PLANS"             component={PlanList}            />
            <AdminRoute url="ADDONS"            component={AddonList}           />
            <AdminRoute url="CONNECTORS"        component={ConnectorTypeList}   />
            <AdminRoute url="CURRENCIES"        component={CurrencyList}        />
            <AdminRoute url="ADMINS"            component={AdminList}           />
            <AdminRoute url="LANGUAGES"         component={LanguageList}        />
            <AdminRoute url="COUNTRIES"         component={CountryList}         />
            <AdminRoute url="PROVINCES"         component={ProvinceList}        />
            <AdminRoute url="ASSISTANTS_MODELS" component={AssistantModelList}  />
            <AdminRoute url="FLOWS"             component={FlowTemplateList}    />
            <AdminRoute url="FLOW"              component={FlowTemplatePage}    />
            <AdminRoute url="AUTOMATIONS"      component={AutomationTempList}  />
            <AdminRoute url="AUTOMATION"       component={AutomationTempPage}  />
            <AdminRoute url="MEDIA"             component={MediaPage}           />
            <AdminRoute url="EMAIL_TEMPLATES"   component={EmailTemplateList}   />
            <AdminRoute url="EMAIL_WHITE_LIST"  component={EmailWhiteList}      />
        </Router>
    </>;
}

export default SetupContainer;
