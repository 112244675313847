import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Flow Move Nodes Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowMoveNodes(props) {
    const { open, flowID, onClose, onSubmit } = props;

    const { flows } = Store.useState("flowEditor");
    const { selectedNodes } = Store.useState("flowState");
    const { moveNodes } = Store.useAction("flowEditor");


    // The Initial Data
    const initialData = {
        createFlow  : 0,
        newFlowID   : 0,
        flowName    : "",
        deleteNodes : 0,
        gotoFlow    : 0,
    };


    // Initializes the Data
    React.useEffect(() => {
        const createFlow = flows.length === 0;
        setElem({ ...initialData, createFlow });
    }, [ open ]);

    // Handles the Edit
    const handleEdit = async (data) => {
        const newFlowID = await moveNodes({
            ...data, flowID,
            nodes : JSON.stringify(selectedNodes),
        });
        onSubmit(newFlowID);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flow", initialData, handleEdit);



    // Do the Render
    const canSelect = flows.length > 0;

    return <EditDialog
        open={open}
        icon="move"
        title="FLOWS_NODES_MOVE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
    >
        <DialogMessage message="FLOWS_NODES_MOVE_TEXT" />

        <InputField
            isHidden={!canSelect}
            type="toggle"
            name="createFlow"
            label="FLOWS_NODES_NEW_FLOW"
            value={data.createFlow}
            error={errors.createFlow}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!canSelect || !!data.createFlow}
            type="select"
            name="newFlowID"
            label="FLOWS_NODES_NEW_FLOW_ID"
            value={data.newFlowID}
            error={errors.newFlowID}
            options={flows}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!data.createFlow}
            name="flowName"
            label="FLOWS_NODES_NEW_FLOW_NAME"
            value={data.flowName}
            error={errors.flowName}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="deleteNodes"
            label="FLOWS_NODES_DELETE_NODES"
            value={data.deleteNodes}
            error={errors.deleteNodes}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="gotoFlow"
            label="FLOWS_NODES_GOTO_FLOW"
            value={data.gotoFlow}
            error={errors.gotoFlow}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowMoveNodes.propTypes = {
    open     : PropTypes.bool.isRequired,
    flowID   : PropTypes.number.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default FlowMoveNodes;
