import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";

// The API
import {
    Contact, ContactIO,
} from "Utils/API";



// The initial State
const initialState = {
    loading        : true,
    error          : false,
    edition        : 0,
    canCreate      : false,
    canEdit        : false,
    canFilter      : false,
    canImport      : false,
    canExport      : false,
    list           : [],
    total          : 0,
    module         : "",
    columns        : [],
    elem           : {
        id     : 0,
        fields : [],
        tags   : [],
    },
    hospitality    : {},
    sale           : {},
    order          : {},
    accounts       : [],
    conversations  : [],
    editElem       : {},
    contactOrigins : [],
    countries      : [],
    tongues        : [],
    customFields   : [],
    tags           : [],
    hasInstagram   : false,
    hasTelegram    : false,
    filters        : {},
    hasFilters     : false,
    sort           : {
        orderBy  : "fullName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONTACT_LOADING" });
    },

    /**
     * Fetches the Contact List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await Contact.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "CONTACT_LIST", data });
    },

    /**
     * Fetches a single Contact
     * @param {Function} dispatch
     * @param {Number}   contactID
     * @returns {Promise}
     */
    async fetchElem(dispatch, contactID) {
        const data = await Contact.getOne({ contactID });
        dispatch({ type : "CONTACT_ELEM", data });
    },

    /**
     * Removes the single Contact
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "CONTACT_REMOVE" });
    },

    /**
     * Fetches the Contact Edit data
     * @param {Function} dispatch
     * @param {Number}   contactID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, contactID, clientID) {
        const data = await Contact.getEditData({ contactID, clientID });
        dispatch({ type : "CONTACT_EDIT", data });
    },

    /**
     * Edits/Creates a Contact
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editContact(dispatch, data) {
        return Contact.edit(data);
    },


    /**
     * Exports the Contacts
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Object}   filters
     * @returns {String}
     */
    exportContacts(dispatch, clientID, filters) {
        return ContactIO.export({ ...filters, clientID });
    },

    /**
     * Uploads the Import File
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    uploadImport(dispatch, data) {
        return ContactIO.uploadImport(data);
    },

    /**
     * Downloads the Import File
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {String}
     */
    downloadImport(dispatch, data) {
        return ContactIO.downloadImport(data);
    },

    /**
     * Imports the Contacts
     * @param {Function} dispatch
     * @param {Object}   data
     * @param {Object}   controller
     * @returns {Promise}
     */
    importContacts(dispatch, data, controller) {
        return ContactIO.import(data, controller);
    },

    /**
     * Blocks a Contact
     * @param {Function} dispatch
     * @param {Number}   contactID
     * @returns {Promise}
     */
    blockContact(dispatch, contactID) {
        return Contact.block({ contactID });
    },

    /**
     * Unblocks a Contact
     * @param {Function} dispatch
     * @param {Number}   contactID
     * @returns {Promise}
     */
    unblockContact(dispatch, contactID) {
        return Contact.unblock({ contactID });
    },

    /**
     * Deletes a single Contact
     * @param {Function} dispatch
     * @param {Number}   contactID
     * @returns {Promise}
     */
    deleteContact(dispatch, contactID) {
        return Contact.delete({ contactID });
    },

    /**
     * Deletes multiple Contacts
     * @param {Function} dispatch
     * @param {Number[]} contactIDs
     * @returns {Promise}
     */
    deleteContacts(dispatch, contactIDs) {
        return Contact.multiDelete({ contactIDs : JSON.stringify(contactIDs) });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONTACT_LIST", "CONTACT_ELEM", "CONTACT_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONTACT_LOADING":
        return {
            ...state,
            loading        : true,
        };

    case "CONTACT_LIST":
        return {
            ...state,
            loading        : false,
            error          : false,
            canCreate      : action.data.canCreate,
            canEdit        : action.data.canEdit,
            canFilter      : action.data.canFilter,
            canImport      : action.data.canImport,
            canExport      : action.data.canExport,
            list           : Utils.parseList(action.data.list, Commons.parseContact),
            total          : action.data.total,
            module         : action.data.module,
            columns        : action.data.columns,
            customFields   : action.data.customFields,
            filters        : action.data.filters,
            hasFilters     : !Utils.isEmpty(action.data.filters),
            sort           : action.data.sort,
        };

    case "CONTACT_ELEM":
        return {
            ...state,
            error          : false,
            edition        : state.edition + 1,
            elem           : Commons.parseContact(action.data.elem),
            hospitality    : Commons.parseHospitality(action.data.hospitality),
            sale           : Commons.parseSale(action.data.sale),
            order          : Commons.parseOrder(action.data.order),
            accounts       : action.data.accounts,
            conversations  : Conversations.parseList(action.data.conversations),
        };

    case "CONTACT_EDIT":
        return {
            ...state,
            error          : false,
            edition        : state.edition + 1,
            editElem       : Commons.parseContact(action.data.elem),
            contactOrigins : action.data.contactOrigins,
            countries      : action.data.countries,
            tongues        : action.data.tongues,
            customFields   : action.data.customFields,
            tags           : action.data.tags,
            hasInstagram   : action.data.hasInstagram,
            hasTelegram    : action.data.hasTelegram,
        };

    case "CONTACT_REMOVE":
        return {
            ...state,
            error          : false,
            edition        : state.edition + 1,
            elem           : { ...initialState.elem },
            conversations  : [],
        };

    case "CONTACT_COLUMNS":
        return {
            ...state,
            columns        : action.columns,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
