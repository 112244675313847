import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import ClientFilter         from "./ClientFilter";
import ClientEdit           from "./ClientEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Client List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("client", type);

    const forPartner = type === "PARTNER";
    const partnerID  = Hooks.usePartnerID();
    const navigate   = Navigate.useGoto();

    const data = Store.useState("client");
    const { loading, canEdit, canApprove, list, total, filters, sort } = data;

    const { exportClients, approveClient, deleteClient } = Store.useAction("client");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Returns true if the View is hidden
    const hideView = (elemID) => {
        return Utils.getValue(list, "clientID", elemID, "pendingApproval");
    };

    // Returns true if the Partner is hidden
    const hidePartner = (elemID) => {
        const partnerID = Utils.getValue(list, "clientID", elemID, "partnerID");
        return forPartner || !partnerID;
    };

    // Returns true if the Approve is hidden
    const hideApprove = (elemID) => {
        return !canApprove || !hideView(elemID);
    };


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isPartner) {
            endAction();
            const partnerID = Utils.getValue(list, "clientID", elemID, "partnerID");
            navigate("PARTNERS", partnerID);
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = exportClients(filters);
            await Utils.download(source, "clients.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Approve Submit
    const handleApprove = async () => {
        endAction();
        const result = await approveClient(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteClient(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "clientID", elemID, "name");

    return <Main withNavigation={forPartner}>
        <Header message="CLIENTS_NAME" icon="client">
            <ActionList data={data} onAction={handleAction} isExporting={exporting} />
        </Header>
        <Content isLoading={loading}>
            <Table fetch={load} sort={sort} none="CLIENTS_NONE_AVAILABLE">
                <TableHead>
                    <TableHeader field="name"                 message="GENERAL_NAME"      grow="2.5" isTitle />
                    <TableHeader field="partnerName"          message="PARTNERS_SINGULAR" grow="2" isHidden={forPartner} />
                    <TableHeader field="planID"               message="PLANS_SINGULAR"    grow="1.5" />
                    <TableHeader field="amount"               message="GENERAL_AMOUNT"    maxWidth="120" noSorting />
                    <TableHeader field="campaignDispatches"   message="CAMPAIGNS_NAME"    maxWidth="120" />
                    <TableHeader field="automationExecutions" message="AUTOMATIONS_NAME"  maxWidth="120" />
                    <TableHeader field="price"                message="GENERAL_PRICE"     grow="1.5" noSorting />
                    <TableHeader field="addonPrice"           message="ADDONS_NAME"       maxWidth="100" align="right" noSorting />
                    <TableHeader field="connectorPrice"       message="CONNECTORS_NAME"   maxWidth="100" align="right" noSorting />
                    <TableHeader field="totalPrice"           message="GENERAL_TOTAL"     maxWidth="100" align="right" noSorting />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.clientID} elemID={elem.clientID}>
                        <TableCell message={elem.name}               className={elem.nameClass} circle={elem.statusColor} />
                        <TableCell message={elem.partnerName}        />
                        <TableCell message={elem.planName}           />
                        <TableCell message={elem.amountText}         />
                        <TableCell message={elem.campaignText}       />
                        <TableCell message={elem.automationText}     />
                        <TableCell message={elem.basePriceText}      />
                        <TableCell message={elem.addonPriceText}     />
                        <TableCell message={elem.connectorPriceText} />
                        <TableCell message={elem.totalPriceText}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"    message="CLIENTS_VIEW_TITLE"    hide={hideView} navigate />
                    <TableAction action="PARTNER" message="PARTNERS_VIEW_TITLE"   hide={hidePartner} />
                    <TableAction action="EDIT"    message="CLIENTS_EDIT_TITLE"    />
                    <TableAction action="APPROVE" message="CLIENTS_APPROVE_TITLE" hide={hideApprove} />
                    <TableAction action="DELETE"  message="CLIENTS_DELETE_TITLE"  />
                </TableActionList>
            </Table>
        </Content>

        <ClientFilter
            open={action.isFilter}
            forPartner={forPartner}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ClientEdit
            open={action.isVCE}
            elemID={elemID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isApprove}
            icon="check"
            title="CLIENTS_APPROVE_TITLE"
            message="CLIENTS_APPROVE_TEXT"
            content={elemName}
            onSubmit={handleApprove}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CLIENTS_DELETE_TITLE"
            message="CLIENTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ClientList;
