import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Language Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function LanguageEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editLanguage } = Store.useAction("language");


    // The Initial Data
    const initialData = {
        languageID      : 0,
        name            : "",
        englishName     : "",
        isoCode         : "",
        words           : "['']",
        position        : 0,
        createByDefault : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("language", initialData, editLanguage, onSubmit);

    // Load the Data
    const { loading } = useDialog("language", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="language"
        title={elemID ? "LANGUAGES_EDIT_TITLE" : "LANGUAGES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="englishName"
            label="LANGUAGES_ENGLISH_NAME"
            helperText="LANGUAGES_ENGLISH_NAME_TIP"
            value={data.englishName}
            error={errors.englishName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="isoCode"
            label="GENERAL_ISO_CODE"
            value={data.isoCode}
            error={errors.isoCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="list"
            name="words"
            label="LANGUAGES_WORDS"
            value={data.words}
            error={errors.words}
            onChange={handleChange}
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
        <InputField
            type="toggle"
            name="createByDefault"
            label="LANGUAGES_CREATE_BY_DEFAULT"
            value={data.createByDefault}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
LanguageEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default LanguageEdit;
