import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { FlowTemplate }     from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    charging  : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    statuses  : [],
    sort      : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "FLOW_TEMPLATE_LOADING" });
    },

    /**
     * Fetches the Flow Template List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        let data = null;

        if (type === "PARTNER") {
            params.partnerID = elemID;
            data = await FlowTemplate.getAllForPartner(params);
        } else {
            data = await FlowTemplate.getAll(params);
        }
        data.sort = params;
        dispatch({ type : "FLOW_STATE_RESET" });
        dispatch({ type : "FLOW_TEMPLATE_LIST", data });
    },

    /**
     * Fetches a single Flow Template
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    async fetchElem(dispatch, flowID) {
        dispatch({ type : "FLOW_TEMPLATE_CHARGING" });
        const data = await FlowTemplate.getOne({ flowID });
        dispatch({ type : "FLOW_DATA", data });
        dispatch({ type : "FLOW_TEMPLATE_ELEM", data });
        dispatch({ type : "FLOW_EDITOR_PUBLISH", data });
        return data;
    },

    /**
     * Fetches the Flow Template Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await FlowTemplate.getEditData();
        dispatch({ type : "FLOW_TEMPLATE_EDIT", data });
    },

    /**
     * Edits/Creates a Flow Template
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editFlow(dispatch, data) {
        return FlowTemplate.edit(data);
    },

    /**
     * Copies a Flow Template
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    copyFlow(dispatch, data) {
        return FlowTemplate.copy(data);
    },

    /**
     * Deletes a Flow Template
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    deleteFlow(dispatch, flowID) {
        return FlowTemplate.delete({ flowID });
    },

    /**
     * Publishes a Flow Template
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    publishFlow(dispatch, flowID) {
        return FlowTemplate.publish({ flowID });
    },

    /**
     * Clears a Flow Template
     * @param {Function} dispatch
     * @param {Number}   flowID
     * @returns {Promise}
     */
    clearFlow(dispatch, flowID) {
        return FlowTemplate.clear({ flowID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.modifiedDate     = DateTime.formatDate(elem.modifiedTime, "dashes");
    elem.modifiedDateTime = DateTime.formatDate(elem.modifiedTime, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "FLOW_TEMPLATE_LIST", "FLOW_TEMPLATE_ELEM", "FLOW_TEMPLATE_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "FLOW_TEMPLATE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "FLOW_TEMPLATE_CHARGING":
        return {
            ...state,
            charging  : true,
        };

    case "FLOW_TEMPLATE_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "FLOW_TEMPLATE_ELEM":
        return {
            ...state,
            charging   : false,
            error      : false,
            edition    : state.edition + 1,
            canEdit    : action.data.canEdit,
            elem       : parseElem(action.data.elem),
            statuses   : action.data.statuses,
        };

    case "FLOW_TEMPLATE_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            statuses   : action.data.statuses,
        };

    case "FLOW_EDITOR_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
