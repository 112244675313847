import React                from "react";
import PropTypes            from "prop-types";
import { Line }             from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import StatList             from "Dashboard/Components/Stats/StatList";
import StatItem             from "Dashboard/Components/Stats/StatItem";



// Styles
const Container = Styled.div`
    position: relative;
    box-sizing: border-box;
    height: calc(var(--report-height) - var(--stats-height) - var(--main-gap));
`;



/**
 * The Line Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function LineChart(props) {
    const { labels, amounts, colors } = props;


    // Chart Options
    const options = {
        maintainAspectRatio : false,
        interaction : {
            mode      : "index",
            intersect : false,
        },
        scales : {
            y : {
                min   : 0,
                grid  : { display : false },
                ticks : { precision : 0 },
            },
            x : {
                grid  : { display : false },
            },
        },
        plugins : {
            tooltip : {
                cornerRadius  : 12,
                padding       : 8,
                boxPadding    : 4,
                usePointStyle : true,
            },
            legend : {
                labels : {
                    usePointStyle : true,
                },
            },
        },
    };

    // Create the Dataset
    const dataset = React.useMemo(() => {
        return {
            labels   : labels,
            datasets : Object.entries(amounts).map(([ label, data ], index) => {
                const color = colors?.[index] ?? "";
                return {
                    label            : NLS.get(label),
                    data             : data,
                    borderColor      : color,
                    backgroundColor  : color.replace(")", ", 0.8)"),
                    pointBorderWidth : 1,
                    tension          : 0.1,
                };
            }),
        };
    }, [ JSON.stringify(labels), JSON.stringify(amounts), JSON.stringify(colors) ]);

    // Create the Stats
    const stats = React.useMemo(() => {
        return Object.entries(amounts).map(([ label, data ]) => ({
            message : label,
            value   : data.reduce((sum, value) => sum + value, 0),
        }));
    }, [ JSON.stringify(amounts) ]);


    // Do the Render
    return <>
        <StatList variant="outlined">
            {stats.map(({ message, value }) => <StatItem
                key={message}
                message={message}
                value={value}
            />)}
        </StatList>

        <Container>
            <Line
                // @ts-ignore
                options={options}
                data={dataset}
            />
        </Container>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
LineChart.propTypes = {
    labels  : PropTypes.array.isRequired,
    amounts : PropTypes.object.isRequired,
    colors  : PropTypes.array,
};

export default LineChart;
