import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import AutomationCreate     from "../Dialogs/AutomationCreate";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Automation Editor Dialogs
 * @returns {React.ReactElement}
 */
function AutomationDialogs() {
    const { automationID } = Store.useState("automationEditor");
    const { deleteAction } = Store.useAction("automationEditor");

    const {
        action, position, forTrigger,
        toSelectAction, selectedAction,
    } = Store.useState("automationState");

    const {
        setAction, setHasChanges,
        setToSelectAction, setSelectedAction,
    } = Store.useAction("automationState");


    // Handles the Action Create
    const handleCreate = () => {
        setAction();
    };

    // Handles the Action Deletes
    const handleDeleteAction = () => {
        if (selectedAction) {
            deleteAction(selectedAction);
            setSelectedAction(0);
            setHasChanges(false);
        }
        setAction();
    };

    // Handles the Discard
    const handleDiscard = () => {
        setSelectedAction(toSelectAction);
        setToSelectAction(0);
        setHasChanges(false);
        setAction();
    };


    // Do the Render
    return <>
        <AutomationCreate
            open={action.isCreate}
            automationID={automationID}
            position={position}
            forTrigger={forTrigger}
            onSubmit={handleCreate}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isRemove}
            title="AUTOMATIONS_ACTIONS_DELETE_TITLE"
            message="AUTOMATIONS_ACTIONS_DELETE_TEXT"
            onSubmit={handleDeleteAction}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isWarning}
            icon="status"
            title="AUTOMATIONS_ACTIONS_WARNING_TITLE"
            message="AUTOMATIONS_ACTIONS_WARNING_TEXT"
            primary="GENERAL_DISCARD"
            onSubmit={handleDiscard}
            onClose={setAction}
        />
    </>;
}

export default AutomationDialogs;
