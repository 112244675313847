import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import SnackBar             from "Dashboard/Components/Core/SnackBar";



/**
 * The Announcement Bar
 * @returns {React.ReactElement}
 */
function AnnouncementBar() {
    const navigate = Navigate.useGotoUrl();

    const { announcement } = Store.useState("app");
    const { showNotificationBar } = Store.useState("notification");

    const { discardAnnouncement } = Store.useAction("app");


    // Handles the Accept
    const handleAccept = () => {
        if (announcement.url) {
            navigate(announcement.url,  "_blank");
        }
        discardAnnouncement(announcement.id);
    };

    // Handles the Close
    const handleClose = () => {
        discardAnnouncement(announcement.id);
    };


    // Variables
    const showAnnouncement = Boolean(announcement && announcement.id && !showNotificationBar);


    // Do the Render
    return <SnackBar
        isHidden={!showAnnouncement}
        message={announcement.message}
        buttonText={announcement.url ? "GENERAL_VIEW_MORE" : ""}
        autoClose={announcement.autoCloseMins}
        onAccept={handleAccept}
        onClose={handleClose}
    />;
}

export default AnnouncementBar;
