import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div.attrs(({ isSelected, isNotAllowed, isDisabled }) => ({ isSelected, isNotAllowed, isDisabled }))`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: var(--inters-action);
    max-width: 100%;
    margin: 0 auto;
    padding: 16px;
    background-color: var(--content-color);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius-big);
    box-shadow: rgba(9, 30, 66, 0.10) 0px 4px 8px -2px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    :hover {
        box-shadow: rgba(9, 30, 66, 0.40) 0px 4px 8px -2px;
    }

    ${(props) => props.isSelected && `
        box-shadow: rgba(9, 30, 66, 0.40) 0px 4px 8px -2px;
    `}

    ${(props) => (props.isNotAllowed || props.isDisabled) && `
        opacity: 0.5;
    `}
`;

const ActionIcon = Styled(Icon).attrs(({ isNotSelected, color }) => ({ isNotSelected, color }))`
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 28px;
    border-radius: var(--border-radius);
    background-color: ${(props) => props.color || "var(--primary-color)"};
    color: white;

    ${(props) => props.isNotSelected && `
        background-color: var(--white-color);
        color: gray;
        border: 1px solid var(--border-color-light);
    `}
`;

const Content = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3, p {
        margin: 0;
    }
`;

const Errors = Styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    background-color: var(--error-color);
    border-radius: var(--border-radius);
    transform: translate(25%, -25%);
`;

const Changes = Styled.div`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border: 1px solid white;
    background-color: var(--primary-color);
    border-radius: 9999px;
    transform: translate(25%, -25%);
`;



/**
 * The Automation Action
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AutomationAction(props) {
    const {
        actionID, icon, position, title, message, color,
        isAllowed, isDisabled, hasChanges, onClick,
    } = props;

    const { publishErrors } = Store.useState("automationEditor");
    const { viewErrors, selectedAction } = Store.useState("automationState");


    // Variables
    const isSelected    = selectedAction === actionID;
    const isNotSelected = Boolean(selectedAction && selectedAction !== actionID);
    const errors        = Utils.getValue(publishErrors, "id", actionID);
    const errorCount    = errors ? Utils.count(errors.errors) : 0;
    const showError     = Boolean(viewErrors && errorCount > 0);
    const showChanges   = Boolean(!showError && hasChanges);


    // Do the Render
    return <Container
        className={`automation-action-${actionID}`}
        isSelected={isSelected}
        isNotAllowed={!isAllowed}
        isDisabled={isDisabled}
        onClick={onClick}
    >
        <ActionIcon
            isNotSelected={isNotSelected}
            icon={icon}
            color={color}
        />
        <Content>
            <h3>{`${position}. ${NLS.get(title)}`}</h3>
            <p>{NLS.get(message)}</p>
        </Content>
        {showError && <Errors>{errorCount}</Errors>}
        {showChanges && <Changes />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AutomationAction.propTypes = {
    actionID   : PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    position   : PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    color      : PropTypes.string,
    icon       : PropTypes.string.isRequired,
    title      : PropTypes.string.isRequired,
    message    : PropTypes.string.isRequired,
    errorCount : PropTypes.number,
    isAllowed  : PropTypes.bool,
    isDisabled : PropTypes.bool,
    hasChanges : PropTypes.bool,
    onClick    : PropTypes.func.isRequired,
};

export default AutomationAction;
