import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import AutomationCanvas     from "../Canvas/AutomationCanvas";
import AutomationDetails    from "./AutomationDetails";
import AutomationDialogs    from "./AutomationDialogs";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.main.attrs(({ hasTabs }) => ({ hasTabs }))`
    --inters-height-final: ${(props) => props.hasTabs ? "var(--inters-height-tabs)" : "var(--inters-height)"};

    display: flex;
    padding-top: var(--main-gap);
`;



/**
 * The Automation Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AutomationEditor(props) {
    const { automationID, hasTabs } = props;

    const { readying } = Store.useState("automationEditor");
    const { fetchEditor, fetchUpdate } = Store.useAction("automationEditor");
    const { setHasChanges } = Store.useAction("automationState");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Remove the Auto-update
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Load the Data
    React.useEffect(() => {
        if (automationID) {
            fetchEditor(automationID);
            setHasChanges(false);
            setAutoUpdate();
        }
    }, [ automationID ]);

    // Handle the Update
    React.useEffect(() => {
        fetchUpdate(automationID);
        setAutoUpdate();
    }, [ update ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };


    // Do the Render
    if (readying) {
        return <CircularLoader />;
    }
    return <Container hasTabs={hasTabs}>
        <AutomationCanvas />
        <AutomationDetails hasExternalTabs={hasTabs} />
        <AutomationDialogs />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AutomationEditor.propTypes = {
    automationID : PropTypes.number,
    hasTabs      : PropTypes.bool.isRequired,
};

export default AutomationEditor;
