import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import SaleItem             from "Components/Utils/Details/SaleItem";
import ContactItem          from "Components/Utils/Details/ContactItem";
import ConversationsItem    from "Components/Utils/Details/ConversationsItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";




/**
 * The Sale Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SaleDetails(props) {
    const { startAction } = props;
    const { canEdit, elem, contact, conversations } = Store.useState("sale");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    return <Details
        isHidden={!elem.id}
        collapsible="sale"
        onAction={handleAction}
    >
        <SaleItem
            action="EDIT"
            elem={elem}
            canEdit={canEdit}
        />
        <ContactItem
            action="CONTACT"
            elem={contact}
            canEdit
        />
        <ConversationsItem
            list={conversations}
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SaleDetails.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default SaleDetails;
