import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Hooks                from "Utils/Hooks";

// Components
import AutomationTempEdit   from "./AutomationTempEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Automation Template Dialogs
 * @returns {React.ReactElement}
 */
function AutomationTempDialogs() {
    const { openDetails } = Store.useAction("core");

    const partnerID = Hooks.usePartnerID();
    const navigate  = Hooks.useGoto();

    const { elem } = Store.useState("automation");
    const {
        fetchElem, activateAutomation, publishAutomation,
        clearAutomation, pauseAutomation, deleteAutomation,
    } = Store.useAction("automationTemplate");

    const { inActivate, action } = Store.useState("automationState");
    const { setAction, setInActivate, setInPublish } = Store.useAction("automationState");

    const { totalChanges } = Store.useState("automationEditor");
    const { fetchEditor } = Store.useAction("automationEditor");


    // Goes to the Automation
    const gotoAutomation = (elemID = "") => {
        if (partnerID) {
            navigate("AUTOMATIONS", elemID);
        } else {
            navigate("SETTINGS", "AUTOMATIONS", elemID);
        }
    };

    // Handles the Automation Edit
    const handleEdit = (response) => {
        setAction();
        if (response.automationID !== elem.id) {
            gotoAutomation(response.automationID);
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        setAction();
        const result = await activateAutomation(elem.id);
        if (result.success) {
            setInActivate(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishAutomation(elem.id);
        if (result.success) {
            setInPublish(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearAutomation(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        setAction();
        const result = await pauseAutomation(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        const result = await deleteAutomation(elem.id);
        if (result.success) {
            gotoAutomation();
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        openDetails();
    };

    // Returns the Error Message
    const getErrorsText = () => {
        const parts = [
            "TEXT",
            inActivate ? "ACTIVATE" : "PUBLISH",
            "PANEL",
        ];
        return parts.map((text) => NLS.get(`AUTOMATIONS_TEMPS_ERRORS_${text}`)).join(" ");
    };



    // Do the Render
    return <>
        <AutomationTempEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="AUTOMATIONS_ACTIVATE_TITLE"
            message="AUTOMATIONS_ACTIVATE_TEXT"
            content={elem.name}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <AlertDialog
            open={action.isPublish && totalChanges === 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="AUTOMATIONS_TEMPS_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish && totalChanges > 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={NLS.pluralize("AUTOMATIONS_TEMPS_PUBLISH", totalChanges, elem.name)}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="AUTOMATIONS_TEMPS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="AUTOMATIONS_TEMPS_PAUSE_TITLE"
            message="AUTOMATIONS_TEMPS_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
            isWide
        />
        <DeleteDialog
            open={action.isDelete}
            title="AUTOMATIONS_TEMPS_DELETE_TITLE"
            message="AUTOMATIONS_TEMPS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
            isWide
        />

        <ConfirmDialog
            open={action.isError}
            icon={inActivate ? "check" : "publish"}
            title={inActivate ? "AUTOMATIONS_TEMPS_ACTIVATE_TITLE" : "GENERAL_PUBLISH_CHANGES"}
            message={getErrorsText()}
            onSubmit={handleErrors}
            onClose={setAction}
        />
    </>;
}

export default AutomationTempDialogs;
