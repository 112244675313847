import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import StoreEdit            from "./StoreEdit";

// Dashboard
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Store List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function StoreList(props) {
    const { type } = props;
    const { fetch, load } = useList("store", type);

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const data = Store.useState("store");
    const { loading, canEdit, list, total, sort } = data;

    const { activateStore, pauseStore, deleteStore } = Store.useAction("store");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Goes to the Store
    const gotoStore = (elemID, tabUrl) => {
        navigate("STORES", elemID, tabUrl);
    };

    // Handles the Edit Submit
    const handleEdit = (response) => {
        if (action.isCreate && response.storeID) {
            gotoStore(response.storeID);
        } else {
            fetch();
        }
        endAction();
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        endAction();
        const result = await activateStore(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        endAction();
        const result = await pauseStore(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteStore(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Returns true if the Activate is hidden
    const hideActivate = (elemID) => {
        const isActive = Utils.getValue(list, "storeID", elemID, "isActive");
        return !canEdit || isActive;
    };

    // Returns true if the Pause is hidden
    const hidePause = (elemID) => {
        const isActive = Utils.getValue(list, "storeID", elemID, "isActive");
        return !canEdit || !isActive;
    };


    // Do the Render
    const elemName = Utils.getValue(list, "storeID", elemID, "name");

    return <>
        <Header message="STORES_NAME" icon="store">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="STORES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"     message="GENERAL_NAME"     isTitle />
                    <TableHeader field="status"   message="GENERAL_STATUS"   maxWidth="100" />
                    <TableHeader field="position" message="GENERAL_POSITION" maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.storeID} elemID={elem.storeID}>
                        <TableCell message={elem.name}       />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                        <TableCell message={elem.position}   />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"     message="STORES_EDIT_TITLE"     />
                    <TableAction action="ACTIVATE" message="STORES_ACTIVATE_TITLE" hide={hideActivate} />
                    <TableAction action="PAUSE"    message="STORES_PAUSE_TITLE"    hide={hidePause} />
                    <TableAction action="DELETE"   message="STORES_DELETE_TITLE"   />
                </TableActionList>
            </Table>
        </Content>

        <StoreEdit
            open={action.isCE}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="STORES_ACTIVATE_TITLE"
            message="STORES_ACTIVATE_TEXT"
            content={elemName}
            onSubmit={handleActivate}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="STORES_PAUSE_TITLE"
            message="STORES_PAUSE_TEXT"
            content={elemName}
            onSubmit={handlePause}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="STORES_DELETE_TITLE"
            message="STORES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
StoreList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default StoreList;
