import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Product Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProductEdit(props) {
    const { open, isCopy, elemID, onClose, onSubmit } = props;

    const { elem } = Store.useState("store");
    const { categories, customFields, tongues, statuses } = Store.useState("storeProduct");
    const { editProduct } = Store.useAction("storeProduct");


    // The Current State
    const [ tongueID, setTongueID ] = React.useState(0);

    // The Initial Data
    const initialData = {
        categoryID : 0,
        externalID : "",
        image      : "",
        price      : "",
        stock      : "",
        unlimited  : 0,
        status     : "Active",
        position   : 0,
        values     : {},
    };
    for (const customField of customFields) {
        if (customField.inputType === "text" || customField.inputType === "textarea") {
            for (const tongue of tongues) {
                initialData[`${customField.id}-${tongue.key}`] = "";
            }
        }
        initialData[customField.id] = "";
    }

    // Handle the Set
    const handleSet = (elem) => {
        setElem(elem);
        setTongueID(tongues[0].key);
    };

    // Handles the Value
    const handleValue = (name, value) => {
        const elem   = { ...data.values[tongueID], [name] : value };
        const values = { ...data.values, [tongueID] : elem };
        handleChange("values", values);
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editProduct({
            ...data,
            productID : isCopy ? 0 : (elemID || 0),
            storeID   : elem.storeID,
            values    : JSON.stringify(data.values),
        });
    };

    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("storeProduct", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("storeProduct", open, elemID, { storeID : elem.storeID }, handleSet);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "PRODUCTS_COPY_TITLE";
        }
        if (elemID) {
            return "PRODUCTS_EDIT_TITLE";
        }
        return "PRODUCTS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    const showTongues = Boolean(tongues.length > 1);

    return <EditDialog
        open={open}
        icon="product"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        {showTongues && <TabList
            variant="lined"
            selected={tongueID}
            onClick={setTongueID}
            inDialog
        >
            {tongues.map(({ key, value }) => <TabItem
                key={key}
                message={value}
                value={key}
            />)}
        </TabList>}

        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.values[tongueID]?.name || ""}
            error={errors.name}
            onChange={handleValue}
            isRequired
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.values[tongueID]?.description || ""}
            error={errors.description}
            onChange={handleValue}
        />
        <MediaField
            name="image"
            label="GENERAL_IMAGE"
            helperText="PRODUCTS_IMAGE_TIP"
            mediaType={MediaType.IMAGE}
            value={data.image}
            error={errors.image}
            clientID={elem.clientID}
            onChange={handleChange}
        />

        <Columns>
            <FieldsInputs
                fields={customFields}
                data={data}
                tongueID={tongueID}
                errors={errors}
                onError={setError}
                onChange={handleChange}
                withRequired
            />

            <InputField
                isHidden={!categories.length}
                type="select"
                name="categoryID"
                label="CATEGORIES_SINGULAR"
                options={categories}
                value={data.categoryID}
                error={errors.categoryID}
                onChange={handleChange}
            />
            <InputField
                className={!categories.length ? "columns-double" : ""}
                name="externalID"
                label="GENERAL_EXTERNAL_ID"
                value={data.externalID}
                error={errors.externalID}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="price"
                icon="money"
                label="GENERAL_PRICE"
                value={data.price}
                error={errors.price}
                onChange={handleChange}
                minValue={0}
            />
            <InputField
                type="double"
                name="stock"
                label="PRODUCTS_STOCK"
                value={data.stock}
                error={errors.stock}
                onChange={handleChange}
            >
                <InputItem
                    type="checkbox"
                    name="unlimited"
                    label="PRODUCTS_UNLIMITED_STOCK"
                    value={data.unlimited}
                />
                <InputItem
                    type="number"
                    name="stock"
                    value={data.stock}
                    minValue={0}
                />
            </InputField>

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
                minValue={0}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProductEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ProductEdit;
