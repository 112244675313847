import React                from "react";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useList              from "Dashboard/Hooks/List";
import Colors               from "Utils/Colors";

// Components
import BarChart             from "Components/Utils/Common/BarChart";

// Dashboard
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";



/**
 * The Automation Report
 * @returns {React.ReactElement}
 */
function AutomationReport() {
    const { loadFilter } = useList("automationReport", "AUTOMATION");

    const data = Store.useState("automationReport");
    const { filters, amounts, labels } = data;


    // Do the Render
    const colors = Object.keys(amounts).map((label) => Colors[label.toUpperCase()]);

    return <>
        <FilterList
            onFilter={loadFilter}
            values={filters}
            hideButton
        >
            <FilterItem
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getLastSelect()}
            />
        </FilterList>

        <BarChart
            labels={labels}
            amounts={amounts}
            colors={colors}
        />
    </>;
}

export default AutomationReport;
