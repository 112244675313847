import React                from "react";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Components
import FlowTemplateEdit     from "./FlowTemplateEdit";

// Dashboard
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Flow Template Dialogs
 * @returns {React.ReactElement}
 */
function FlowTemplateDialogs() {
    const { openDetails } = Store.useAction("core");

    const partnerID = Hooks.usePartnerID();
    const navigate  = Hooks.useGoto();

    const { elem } = Store.useState("flowTemplate");
    const { fetchElem, publishFlow, clearFlow, deleteFlow } = Store.useAction("flowTemplate");

    const { totalChanges, publishErrors } = Store.useState("flowEditor");
    const { fetchEditor } = Store.useAction("flowEditor");

    const { action } = Store.useState("flowState");
    const { setAction, setInPublish } = Store.useAction("flowState");


    // Goes to the Flow
    const gotoFlow = (elemID = "") => {
        if (partnerID) {
            navigate("FLOWS", elemID);
        } else {
            navigate("SETTINGS", "FLOWS", elemID);
        }
    };

    // Handles the Flow Edit
    const handleEdit = (response) => {
        setAction();
        if (response.flowID !== elem.id) {
            gotoFlow(response.flowID);
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        if (elem.needsPublish) {
            const result = await publishFlow(elem.id);
            if (result.success) {
                setInPublish(false);
                fetchElem(elem.id);
            }
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearFlow(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteFlow(elem.id);
        if (result.success) {
            gotoFlow();
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        openDetails();
    };


    // Publish Text
    const publishText = React.useMemo(() => {
        if (!elem.needsPublish) {
            return NLS.format("FLOWS_TEMPLATES_PUBLISH_NO_CHANGES", elem.name);
        }
        if (totalChanges > 0) {
            return NLS.pluralize("FLOWS_TEMPLATES_PUBLISH", totalChanges, elem.name);
        }
        return NLS.format("FLOWS_TEMPLATES_PUBLISH_CHANGES", elem.name);
    }, [ elem.name, elem.needsPublish, totalChanges ]);



    // Do the Render
    return <>
        <FlowTemplateEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishText}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="FLOWS_TEMPLATES_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="FLOWS_TEMPLATES_DELETE_TITLE"
            message="FLOWS_TEMPLATES_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isError}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishErrors.length > 1 ? "FLOWS_TEMPLATES_ERRORS_TEXT" : "FLOWS_TEMPLATES_ERROR_TEXT"}
            content={String(publishErrors.length)}
            onSubmit={handleErrors}
            onClose={setAction}
        />
    </>;
}

export default FlowTemplateDialogs;
