import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Flow Template Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowTemplateEdit(props) {
    const { open, isCopy, elemID, partnerID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("flowTemplate");
    const { editFlow, copyFlow } = Store.useAction("flowTemplate");


    // The Initial Data
    const initialData = {
        flowID    : 0,
        partnerID : 0,
        name      : "",
        status    : "Draft",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyFlow({ ...data, partnerID });
        }
        return editFlow({ ...data, partnerID });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flowTemplate", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("flowTemplate", open, elemID, null, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "FLOWS_TEMPLATES_COPY_TITLE";
        }
        if (elemID) {
            return "FLOWS_TEMPLATES_EDIT_TITLE";
        }
        return "FLOWS_TEMPLATES_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="flow"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowTemplateEdit.propTypes = {
    open      : PropTypes.bool.isRequired,
    isCopy    : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    elemID    : PropTypes.number,
    partnerID : PropTypes.number,
};

export default FlowTemplateEdit;
