import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Automation Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AutomationEdit(props) {
    const { open, isCopy, elemID, clientID, onClose, onSubmit } = props;

    const { automationTemps } = Store.useState("automation");
    const { editAutomation, copyAutomation } = Store.useAction("automation");


    // The Initial Data
    const initialData = {
        clientID         : 0,
        automationID     : 0,
        automationTempID : 0,
        name             : "",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (isCopy) {
            return copyAutomation(data);
        }
        return editAutomation(data);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("automation", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("automation", open, elemID, { clientID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "AUTOMATIONS_COPY_TITLE";
        }
        if (elemID) {
            return "AUTOMATIONS_EDIT_TITLE";
        }
        return "AUTOMATIONS_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    const showTemplates = Boolean(!elemID && automationTemps && automationTemps.length);

    return <EditDialog
        open={open}
        icon="automation"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!showTemplates}
            type="select"
            name="automationTempID"
            label="AUTOMATIONS_TEMPLATE"
            value={data.automationTempID}
            error={errors.automationTempID}
            options={automationTemps}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AutomationEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    clientID : PropTypes.number,
};

export default AutomationEdit;
