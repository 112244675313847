import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import UserFilter           from "./Dialogs/UserFilter";
import UserCreate           from "./Dialogs/UserCreate";
import UserInvite           from "./Dialogs/UserInvite";
import UserEdit             from "./Dialogs/UserEdit";
import TeamUser             from "../Team/TeamUser";

// Dashboard
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";



/**
 * The User Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserDialogs(props) {
    const { action, elemID, endAction, fetch, loadFilter } = props;

    const clientID  = Hooks.useClientID();
    const partnerID = Hooks.usePartnerID();
    const navigate  = Hooks.useGoto();

    const { list, filters, elem } = Store.useState("user");
    const { fetchElem, resendInvite, deleteUser } = Store.useAction("user");


    // The Current State
    const [ showInvited, setShowInvited ] = React.useState(false);


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = (userID) => {
        fetch();
        if (userID === elem.id) {
            fetchElem(userID);
        } else if (userID) {
            navigate("USERS", userID);
        }
        endAction();
    };

    // Handle the Invite Submit
    const handleInvite = (response) => {
        handleEdit(response);
        setShowInvited(true);
    };

    // Handles the Team Submit
    const handleTeam = () => {
        fetchElem(elemID);
        endAction();
    };

    // Handles the Resend Submit
    const handleResend = async () => {
        endAction();
        await resendInvite(elemID);
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteUser(elemID);
        if (result.success) {
            fetch();
            if (elemID === elem.id) {
                navigate("USERS");
            }
        }
        endAction();
    };


    // Do the Render
    const elemName = Utils.getValue(list, "userID", elemID, "displayName");

    return <>
        <UserFilter
            open={action.isFilter}
            filters={filters}
            clientID={clientID}
            partnerID={partnerID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <UserCreate
            open={action.isCreate}
            clientID={clientID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <UserInvite
            open={action.isInvite}
            clientID={clientID}
            partnerID={partnerID}
            onSubmit={handleInvite}
            onClose={endAction}
        />
        <UserEdit
            open={action.isEdit}
            elemID={elemID}
            clientID={clientID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <TeamUser
            open={action.isTeam}
            elemID={elemID}
            elemName={elemName}
            clientID={clientID}
            onSubmit={handleTeam}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isResend}
            icon="send"
            title="USERS_RESEND_TITLE"
            message="USERS_RESEND_TEXT"
            content={elemName}
            onSubmit={handleResend}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="USERS_DELETE_TITLE"
            message="USERS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
        <AlertDialog
            open={showInvited}
            icon="user"
            title="USERS_INVITED_TITLE"
            message="USERS_INVITED_TEXT"
            onClose={() => setShowInvited(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserDialogs.propTypes = {
    action     : PropTypes.object.isRequired,
    elemID     : PropTypes.number,
    endAction  : PropTypes.func.isRequired,
    fetch      : PropTypes.func.isRequired,
    loadFilter : PropTypes.func.isRequired,
};

export default UserDialogs;
