import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Sale Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SaleHeader(props) {
    const { startAction, isExporting } = props;

    const {
        canEdit, canFilter, elem, contact, hasFilters,
    } = Store.useState("sale");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Variables
    const hasEdit    = canEdit && !!elem.id;
    const hasContact = !!contact.id;
    const hasActions = hasEdit || hasContact;


    // Do the Render
    return <Header message="SALES_NAME" icon="sale">
        <ActionList onAction={handleAction}>
            <ActionItem
                isHidden={!canFilter}
                action="FILTER"
                withMark={hasFilters}
            />
            <ActionItem
                isHidden={!hasActions}
                icon="more"
                message="GENERAL_ACTIONS"
                isLoading={isExporting}
            >
                <ActionOption
                    isHidden={!canEdit}
                    action="CREATE"
                    message="SALES_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="EDIT"
                    message="SALES_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!hasContact}
                    action="CONVERSATION"
                    message="CONVERSATIONS_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasContact}
                    action="CONTACT"
                    message="CONTACTS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="DELETE"
                    message="SALES_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SaleHeader.propTypes = {
    startAction : PropTypes.func.isRequired,
    isExporting : PropTypes.bool.isRequired,
};

export default SaleHeader;
