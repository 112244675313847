import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Commons              from "Utils/Commons";
import Conversations        from "Utils/Conversations";
import { FlowSimulation }   from "Utils/API";



// The initial State
const initialState = {
    hasSimulation : false,
    simulationID  : 0,
    currentNodeID : 0,
    messageLength : 0,
    messages      : [],
    items         : [],
    conversation  : {},
    contact       : {},
    hospitality   : {},
    quotation     : {},
    sale          : {},
    order         : {},
    accounts      : [],
    lastUpdate    : 0,
};



// The Actions
const actions = {
    /**
     * Fetches a single Flow Simulation
     * @param {Function} dispatch
     * @param {Number}   simulationID
     * @returns {Promise}
     */
    async fetchSimulation(dispatch, simulationID) {
        const data = await FlowSimulation.getOne({ simulationID });
        dispatch({ type : "FLOW_SIMULATION_ELEM", data });
    },

    /**
     * Fetches new Messages in a Flow Simulation
     * @param {Function} dispatch
     * @param {Number}   simulationID
     * @param {Number}   lastUpdate
     * @returns {Promise}
     */
    async fetchMessages(dispatch, simulationID, lastUpdate) {
        const data = await FlowSimulation.getMessages({ simulationID, lastUpdate });
        if (!data.error) {
            dispatch({ type : "FLOW_SIMULATION_MESSAGES", data });
        }
    },

    /**
     * Starts a Flow Simulation
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async startSimulation(dispatch, data) {
        const result = await FlowSimulation.start(data);
        if (result.success) {
            dispatch({ type : "FLOW_SIMULATION_ELEM", data : result });
        }
        return result;
    },

    /**
     * Sends a Message in a Flow Simulation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async sendMessage(dispatch, params) {
        const data = await FlowSimulation.sendMessage(params);
        if (!data.error) {
            dispatch({ type : "FLOW_SIMULATION_MESSAGES", data });
        }
        return data;
    },

    /**
     * Restarts a Flow Simulation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async restartSimulation(dispatch, params) {
        const data = await FlowSimulation.restart(params);
        if (data.success) {
            dispatch({ type : "FLOW_SIMULATION_ELEM", data });
        }
        return data;
    },

    /**
     * Ends a Flow Simulation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async endSimulation(dispatch, params) {
        const result = await FlowSimulation.end(params);
        if (result.success) {
            dispatch({ type : "FLOW_SIMULATION_END" });
        }
        return result;
    },
};



/**
 * Parses the Messages
 * @param {Object[]} messages
 * @returns {Object[]}
 */
function parseMessages(messages) {
    const list   = messages.sort((a, b) => a.createdTime - b.createdTime);
    const result = [];

    let lastDay  = "";
    let lastType = "";
    let lastID   = 0;
    let index    = -1;

    for (const item of list) {
        const { createdTime, credentialID, contactID, fromFlow, userName } = item;
        const isMine    = !credentialID && !fromFlow;
        const thisDay   = new Date(createdTime * 1000).toDateString();
        const thisType  = isMine ? "mine" : "yours";
        const thisID    = fromFlow ? -1 : (credentialID ? credentialID : contactID);
        const isNewDay  = thisDay !== lastDay;
        const isNewUser = thisType !== lastType || thisID !== lastID;

        if (isNewDay || isNewUser) {
            index       += 1;
            result[index] = {
                isMine, userName,
                dayName : isNewDay ? DateTime.formatDay(createdTime) : "",
                list    : [],
            };
        }
        result[index].list.push(item);

        lastDay  = thisDay;
        lastType = thisType;
        lastID   = thisID;
    }
    return result;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "FLOW_DATA":
    case "FLOW_EDITOR_ELEM":
        return {
            ...state,
            hasSimulation : !!action.data.simulationID,
            simulationID  : action.data.simulationID,
        };

    case "FLOW_SIMULATION_ELEM":
        return {
            ...state,
            hasSimulation : !!action.data.simulationID,
            simulationID  : action.data.simulationID,
            currentNodeID : action.data.currentNodeID,
            messageLength : action.data.messageLength,
            messages      : action.data.messages,
            items         : parseMessages(action.data.messages),
            conversation  : Conversations.parseElem(action.data.conversation),
            contact       : Commons.parseContact(action.data.contact),
            hospitality   : Commons.parseHospitality(action.data.hospitality),
            quotation     : Commons.parseQuotation(action.data.quotation),
            sale          : Commons.parseSale(action.data.sale),
            order         : Commons.parseOrder(action.data.order),
            accounts      : action.data.accounts,
            lastUpdate    : action.data.lastUpdate,
        };

    case "FLOW_SIMULATION_MESSAGES": {
        const messages = Commons.updateList("messageID", state.messages, action.data.messages);
        return {
            ...state,
            currentNodeID : action.data.currentNodeID,
            messages      : messages,
            items         : parseMessages(messages),
            conversation  : Conversations.parseElem(action.data.conversation),
            contact       : Commons.parseContact(action.data.contact),
            hospitality   : Commons.parseHospitality(action.data.hospitality),
            quotation     : Commons.parseQuotation(action.data.quotation),
            sale          : Commons.parseSale(action.data.sale),
            order         : Commons.parseOrder(action.data.order),
            accounts      : action.data.accounts,
            lastUpdate    : action.data.lastUpdate,
        };
    }

    case "FLOW_SIMULATION_END":
        return {
            ...state,
            hasSimulation : false,
            simulationID  : 0,
            currentNodeID : 0,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
