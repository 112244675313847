import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    Store as StoreAPI,
} from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    charging   : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    nav        : [],
    list       : [],
    total      : 0,
    elem       : {},
    module     : "",
    assistants : [],
    sort       : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "STORE_LOADING" });
    },

    /**
     * Fetches the Stores
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchNav(dispatch, clientID) {
        const data = await StoreAPI.getNav({ clientID });
        dispatch({ type : "STORE_NAV", data });
        return data.nav;
    },

    /**
     * Fetches the Store List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await StoreAPI.getAll(params);
        data.sort  = params;
        dispatch({ type : "STORE_LIST", data });
    },

    /**
     * Fetches a single Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    async fetchElem(dispatch, storeID) {
        const data = await StoreAPI.getOne({ storeID });
        dispatch({ type : "STORE_ELEM", data });
        return data;
    },

    /**
     * Fetches the Store Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID) {
        const data = await StoreAPI.getEditData({ clientID });
        dispatch({ type : "STORE_EDIT", data });
    },

    /**
     * Edits/Creates a Store
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editStore(dispatch, data) {
        return StoreAPI.edit(data);
    },

    /**
     * Activates a Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    activateStore(dispatch, storeID) {
        return StoreAPI.activate({ storeID });
    },

    /**
     * Publishes a Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    publishStore(dispatch, storeID) {
        return StoreAPI.publish({ storeID });
    },

    /**
     * Clears a Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    clearStore(dispatch, storeID) {
        return StoreAPI.clear({ storeID });
    },

    /**
     * Pauses a Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    pauseStore(dispatch, storeID) {
        return StoreAPI.pause({ storeID });
    },

    /**
     * Deletes a Store
     * @param {Function} dispatch
     * @param {Number}   storeID
     * @returns {Promise}
     */
    deleteStore(dispatch, storeID) {
        return StoreAPI.delete({ storeID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "STORE_LIST", "STORE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "STORE_LOADING":
        return {
            ...state,
            loading    : true,
        };
    case "STORE_CHARGING":
        return {
            ...state,
            charging   : true,
        };
    case "STORE_NAV":
        return {
            ...state,
            nav        : action.data.nav,
        };

    case "STORE_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            nav        : action.data.nav,
            list       : action.data.list,
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "STORE_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            canEdit    : action.data.canEdit,
            elem       : action.data.elem,
            module     : action.data.module,
            assistants : action.data.assistants,
        };

    case "STORE_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            assistants : action.data.assistants,
        };

    case "STORE_EDITED":
        return {
            ...state,
            elem      : action.data.elem,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
