import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



// Styles
const Grid = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Client Edit Connectors
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditConnectors(props) {
    const { isHidden, data, errors, onChange } = props;

    const { connectors } = Store.useState("client");


    // Handles the Connector
    const handleConnector = (connectorCode, name, value) => {
        const oldData = data.connectors[connectorCode];
        const newData = { ...oldData, [name] : value };

        if (name === "isActive") {
            const quantity = oldData?.quantity ?? 0;
            if (value !== 0 && quantity === 0) {
                newData.quantity = 1;
            } else if (value === 0 && quantity === 1) {
                newData.quantity = 0;
            }
        }

        const connectors = { ...data.connectors, [connectorCode] : newData };
        onChange("connectors", connectors);
    };


    // Do the Render
    return <Grid isHidden={isHidden}>
        <Columns amount="5">
            <>
                {connectors.map(({ key, value }) => <React.Fragment key={key}>
                    <InputField
                        className="columns-double"
                        type="toggle"
                        name={`connectorActive-${key}`}
                        label={value}
                        value={data.connectors[key]?.isActive || 0}
                        onChange={(name, value) => handleConnector(key, "isActive", value)}
                        withBorder
                    />
                    <InputField
                        className="columns-double"
                        type="double"
                        label="GENERAL_PRICE"
                        error={errors[`connectorPrice-${key}`]}
                    >
                        <InputItem
                            type="number"
                            name={`connectorPrice-${key}`}
                            icon="money"
                            value={data.connectors[key]?.price || ""}
                            minValue={0}
                            onChange={(name, value) => handleConnector(key, "price", value)}
                        />
                        <InputItem
                            type="checkbox"
                            name={`connectorPriceFree-${key}`}
                            label="GENERAL_FREE"
                            value={data.connectors[key]?.priceFree || 0}
                            onChange={(name, value) => handleConnector(key, "priceFree", value)}
                        />
                    </InputField>
                    <InputField
                        type="number"
                        name={`connectorQuantity-${key}`}
                        label="GENERAL_AMOUNT"
                        value={data.connectors[key]?.quantity || 0}
                        error={errors[`connectorQuantity-${key}`]}
                        onChange={(name, value) => handleConnector(key, "quantity", value)}
                        minValue={0}
                    />
                </React.Fragment>)}
            </>
        </Columns>

        <Columns topSpace="12" amount="1">
            <InputField
                type="number"
                name="automationLimit"
                label="CLIENTS_AUTOMATION_LIMIT"
                value={data.automationLimit}
                error={errors.automationLimit}
                onChange={onChange}
            />
        </Columns>
    </Grid>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditConnectors.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ClientEditConnectors;
