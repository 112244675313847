import React                 from "react";
import PropTypes             from "prop-types";
import Store                 from "Dashboard/Core/Store";

// Components
import ConversationItem     from "Components/Utils/Details/ConversationItem";
import HospitalityItem      from "Components/Utils/Details/HospitalityItem";
import QuotationItem        from "Components/Utils/Details/QuotationItem";
import SaleItem             from "Components/Utils/Details/SaleItem";
import OrderItem            from "Components/Utils/Details/OrderItem";
import ContactItem          from "Components/Utils/Details/ContactItem";
import AccountsItem         from "Components/Utils/Details/AccountsItem";



/**
 * The Flow Sim Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimDetails(props) {
    const { open } = props;

    const {
        conversation, contact, hospitality, quotation,
        sale, order, accounts,
    } = Store.useState("flowSimulation");


    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    return <>
        <ConversationItem
            collapsible="flow-conversation"
            elem={conversation}
        />
        <HospitalityItem
            collapsible="flow-hospitality"
            elem={hospitality}
        />
        <QuotationItem
            collapsible="flow-quotation"
            elem={quotation}
        />
        <SaleItem
            collapsible="flow-sale"
            elem={sale}
        />
        <OrderItem
            collapsible="flow-order"
            elem={order}
        />
        <ContactItem
            collapsible="flow-contact"
            action="CONTACT"
            elem={contact}
        />
        <AccountsItem
            collapsible="flow-accounts"
            list={accounts}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimDetails.propTypes = {
    open : PropTypes.bool.isRequired,
};

export default FlowSimDetails;
