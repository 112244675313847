import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import SaleFilter           from "./SaleFilter";
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";
import ConversationCreate   from "Components/App/Client/Conversation/Actions/ConversationCreate";



/**
 * The Sale Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SaleDialogs(props) {
    const { action, elemID, endAction, fetch, loadFilter } = props;

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const { list, filters, elem } = Store.useState("sale");
    const { fetchElem } = Store.useAction("sale");


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
        navigate("SALES");
    };

    // Handles the Contact Submit
    const handleContact = () => {
        fetch();
        if (elemID === elem.id) {
            fetchElem(elem.id);
        }
        endAction();
    };

    // Handles the Conversation Submit
    const handleConversation = () => {
        fetch();
        fetchElem(elem.id);
        endAction();
    };

    // Handles the Edit Close
    const handleClose = (update) => {
        if (update) {
            fetch();
            fetchElem(elem.id);
        }
        endAction();
    };


    // Variables
    const listElem = Utils.getValue(list, "saleID", elemID);
    const currElem = listElem.id ? listElem : elem;


    // Do the Render
    return <>
        <SaleFilter
            open={action.isFilter}
            filters={filters}
            clientID={clientID}
            onSubmit={handleFilter}
            onClose={endAction}
        />

        <ContactEdit
            open={action.isContact}
            elemID={currElem.contactID}
            clientID={currElem.clientID}
            onSubmit={handleContact}
            onClose={handleClose}
        />
        <ConversationCreate
            open={action.isConversation}
            clientID={clientID}
            contactID={currElem.contactID || 0}
            onSubmit={handleConversation}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SaleDialogs.propTypes = {
    action     : PropTypes.object.isRequired,
    elemID     : PropTypes.number,
    endAction  : PropTypes.func.isRequired,
    fetch      : PropTypes.func.isRequired,
    loadFilter : PropTypes.func.isRequired,
};

export default SaleDialogs;
