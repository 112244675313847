import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import AutomationTempDiags  from "./AutomationTempDialogs";
import AutomationHeader     from "Components/App/Config/Automation/View/AutomationHeader";
import AutomationEditor     from "Components/App/Config/Automation/Editor/AutomationEditor";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



/**
 * The Automation Template Page
 * @returns {React.ReactElement}
 */
function AutomationTempPage() {
    const { automationID } = Navigate.useParams();


    // Do the Render
    return <>
        <Main withNavigation>
            <AutomationHeader levels={1} />

            <Content>
                <AutomationEditor
                    automationID={automationID}
                    hasTabs={false}
                />
            </Content>
        </Main>

        <AutomationTempDiags />
    </>;
}

export default AutomationTempPage;
